nav {
    padding: var(--paddingSide);
    background-color: var(--offwhite);
    display: grid;
    align-items: center;
    grid-template-columns: 75% auto auto;
    text-align: end;
    transition: background-color 0.5s;
}
#LogoDiv{
    text-align: left;
}
#SstSymbol{
    height: 2.5rem;
    width: auto;
    cursor: pointer;
    margin-right: 10px;
}
#LogoImg{
    height: 2.5rem;
    width: auto;
    cursor: pointer;
}
.navbaritem{
    color: var(--customBlue);
    cursor: pointer;
    text-decoration: none;
}
#loginNavbar {
    padding: var(--paddingSide);
    background-color: var(--offwhite);
    display: grid;
    height: 4.5rem;
    align-items: center;
    grid-template-columns: 25% auto 5% 5%;
    grid-template-rows: 50% 50%;
    text-align: end;
}
#LogoDivLogin {
    text-align: left;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}
#marqueeGrid {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    color: var(--customBlue);
}
#welcomeGrid {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: left;
    color: var(--customBlue);
}
#darkThemeGrid {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: -webkit-right;
}
#logoutGrid {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    text-align: -webkit-right;
}
#actualNavContainer {
    background-color: var(--customBlue);
    padding: var(--paddingSide);
    height: 3rem;
}
#actualNavGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
}
#actualNavContainer #hamburger {
    display: none;
}
@media only screen and (max-width : 1320px) {
    #loginNavbar {
        grid-template-columns: 30% auto 5% 5%;
    }
}
@media only screen and (max-width : 1200px) {
    #actualNavContainer {
        position: relative;
    }
    #actualNavGrid {
        position: absolute;
        width: 50vw;
        top: 3rem;
        left: -50vw;
        grid-template-columns: auto;
        height: 3rem;
        transition: left 1s;
    }
    #actualNavContainer #hamburger {
        display: inline-block;
    }

}
@media only screen and (max-width : 1140px) {
    #loginNavbar {
        grid-template-columns: 35% auto 5% 5%;
    }
}
@media only screen and (max-width : 1000px) {
    #loginNavbar {
        grid-template-columns: 40% auto 5% 5%;
    }
}
@media only screen and (max-width : 900px) {
    #loginNavbar {
        grid-template-columns: 45% auto 5% 5%;
    }
}
@media only screen and (max-width : 810px) {
    #loginNavbar {
        grid-template-columns: 50% auto 7% 7%;
    }
}
@media only screen and (max-width : 620px) {
    #loginNavbar {
        grid-template-columns: 75% auto auto;
    }
    #marqueeGrid {
        display: none;
    }
    #welcomeGrid {
        display: none;
    }
    #darkThemeGrid {
        grid-column-start: 2;
        grid-column-end: 3;
    }
    #logoutGrid {
        grid-column-start: 3;
        grid-column-end: 4;
    }
}
@media only screen and (max-width : 450px) {
    nav {
        grid-template-columns: 65% auto auto;
    }
    #SstSymbol{
        display: none;
    }
}