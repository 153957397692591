#hamburger{
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    height: 3rem;
    width: 3rem;
}
#hamburger > svg > line {
    stroke: var(--offwhite);
    stroke-width: 10;
    stroke-linecap: round;
    stroke-dasharray: 80;
    stroke-dashoffset:  0;
}
#hamburger .line.top{
    --rotation:-45deg;
    transform-origin: 65px 45px;
}
#hamburger .line.bottom{
    --rotation:45deg;
    transform-origin: 60px 55px;
}
#hamburger .line{
    animation: to-open-icon 0.8s forwards;
}
#hamburger.clicked .line{
    animation: to-close-icon 0.8s forwards;
}
@keyframes to-open-icon{
    0%{
        stroke-dashoffset: 0;
        transform: rotate(var(--rotation));
    }
    40%{
        stroke-dashoffset: 79.9;
        transform: rotate(var(--rotation));
    }
    60%{
        stroke-dashoffset: 79.9;
    }
    100%{
        stroke-dashoffset: 0;
    }
}
@keyframes to-close-icon{
    0%{
        stroke-dashoffset: 0;
    }
    40%{
        stroke-dashoffset: 79.9;
    }
    60%{
        stroke-dashoffset: 79.9;
        transform: rotate(var(--rotation));
    }
    100%{
        stroke-dashoffset: 0;
        transform: rotate(var(--rotation));
    }
}