.mailSection {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.mailSection>svg>path,
.mailSection>svg>polyline {
    fill: none;
    stroke: var(--strokeColor);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}

@media only screen and (max-width : 600px) {
    .mailSection {
        height: 1rem;
        width: 1rem;
        margin-right: 0.2rem;
    }
}