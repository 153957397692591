.navBarItems{
    position: relative;
}
.navbarDropdowns{
    display: none;
    position: absolute;
    text-align: center;
    width: 100%;
    background-color: var(--customBlue);
    transition: background-color 0.5s;
}
.navBarItems:hover .navbarDropdowns{
    display: block;
}
.navigationButton{
    display: block;
    width: 100%;
    height: 3rem;
    border: none;
    color: var(--offwhite);
    background-color: var(--customBlue);
    font-weight: var(--fontWeight);
    cursor: pointer;
}
.dropdownLinks{
    display: block;
    padding: 0.2rem 0 0.2rem 0;
    font-weight: var(--fontWeight);
    cursor: pointer;
    color: var(--offwhite);
    text-decoration: none;
}
.dropdownLinksLast{
    display: block;
    padding: 0.2rem 0 0.4rem 0;
    font-weight: var(--fontWeight);
    cursor: pointer;
    color: var(--offwhite);
    text-decoration: none;
}
@media only screen and (max-width : 1200px) {
    .navbarDropdowns{
        top: 0;
        left: 50vw;
    }
}