.editSection {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
    display: inline-block;
}

.editSection>svg>path,
.editSection>svg>polygon {
    fill: var(--fillColor);
}