body{
  margin: 0%;
  padding: 0%;
  font-size: var(--fontSize);
  font-family: var(--font);
  background-color: white;
  transition: background-color 0.5s;
}
body::before{
  background-image: url(./images/homeBackground.webp);
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: -1;
}
input[type="date"] {
  color-scheme: var(--colorScheme);
}