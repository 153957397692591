.whatappSection {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
    display: inline-block;
}

.whatappSection>svg>path {
    fill: var(--fillColor);
}

@media only screen and (max-width : 600px) {
    .whatappSection {
        height: 1rem;
        width: 1rem;
        margin-right: 0.2rem;
    }
}