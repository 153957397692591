.gcnResult, .billResult, .thcResult{
    display: grid;
    padding: 0 30rem 0 30rem;
    grid-template-columns: auto;
}
.gcnResultHeading, .thcResultHeading{
    display: grid;
    grid-template-columns: 15% auto 15% 15% 15%;
    height: 1.7rem;
    align-content: center;
    justify-items: center;
}
.billResultHeading{
    display: grid;
    grid-template-columns: 15% auto 15%;
    height: 1.7rem;
    align-content: center;
    justify-items: center;
}
.gcnResults, .thcResults{
    display: grid;
    grid-template-columns: 15% auto 15% 15% 15%;
}
.billResults{
    display: grid;
    grid-template-columns: 15% auto 15%;
}
.borderBottom{
    border-bottom: 0.1rem solid var(--customLightBlue);
    text-align: center;
}
.ceterItemsVetically{
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
    color: var(--customBlue);
}
#copy, #freightType, #gcnNo{
    font-size: 0.8rem;
}
@media only screen and (max-width : 1400px) {
    .gcnResult, .billResult, .thcResult{
        padding: 0 20rem 0 20rem;
    }
}
@media only screen and (max-width : 1050px) {
    .gcnResult, .billResult, .thcResult{
        padding: 0 10rem 0 10rem;
    }
}
@media only screen and (max-width : 720px) {
    .gcnResult, .billResult, .thcResult{
        padding: 0 1rem 0 1rem;
    }
}
@media only screen and (max-width : 450px) {
    .gcnResults, .thcResults{
        grid-template-columns: 15% auto 18% 18% 18%;
    }
    .gcnResultHeading, .thcResultHeading{
        grid-template-columns: 15% auto 18% 18% 18%;
    }
}