#btnContainer > input {
    margin: 0 0.2rem;
}
.tripsSection {
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    margin-bottom: 0.5rem;
}
.tripHeadGrid{
    background-color: var(--customLightBlue);
    color: var(--black);
    font-size: 0.9rem;
    font-weight: var(--fontWeight);
}
.tripHeadGrid, .tripResultGrid {
    display: grid;
    grid-template-columns: 9% 15% 15% 8% 8% 20% 8% 8% 9%;
    border: 1px solid black;
    border-bottom: none;
}
.tripHeadGrid > div, .tripResultGrid > div {
    padding: 0.2rem;
    border-right: 1px solid black;
}
.tripHeadGrid > div:last-child, .tripResultGrid > div:last-child {
    border: none;
}
.lastChild {
    border-bottom: 1px solid black;
}
.expensesGrid {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 0rem !important;
}
.expensesGrid > div {
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    padding: 0.2rem;
}
.expensesGrid > div:nth-child(even) {
    border-right: none;
}
.expensesGrid > div:nth-last-child(-n + 2) {
    border-bottom: none;
}
.filteredResults {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    align-items: baseline;
}
.filteredResults > div {
    border-bottom: 0.1rem solid var(--customLightBlue);
    text-align: center;
    padding: 0.2rem 0;
}
.filteredResults > div:nth-child(-n + 3) {
    background-color: var(--customLightBlue);
    color: var(--black);
    font-size: 0.9rem;
    font-weight: var(--fontWeight);
    border-bottom: none;
}