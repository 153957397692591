.bookingSection > div > div > select, .selectGcn > div > div > select, .billSection > div > div > select, .selectBill > div > div > select, .clientSection > div > div > select, .destinationSection > div > div > select, .brokerSection > div > div > select, .descriptionSection > div > div > select, .packageSection > div > div > select, .ownerSection > div > div > select, .driverSection > div > div > select, .thcSection > div > div > select, .selectThc > div > div > select, .truckSection > div > div > select, .brokerReport > div > div > select, .monthlyBooking > div > div > select, .billSubmission > div > div > select, .outstandingBillReport > div > div > select, .envelopSection > div > div > select, .chequePrintInput > div > div > select, .uploadSection > div > div > select {
    width: var(--selectBoxWidth);
    height: var(--selectBoxHeight);
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--inputBoxBackgroundColor);
    margin-top: 0.5rem;
    color: var(--fillColor);
}
.cnercneeSection > div > div > select{
    width: 18rem;
    height: var(--selectBoxHeight);
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--inputBoxBackgroundColor);
    color: var(--fillColor);
}
.selectGcn > div, .selectBill > div, .selectThc > div{
    display: grid;
    padding: var(--paddingSide);
    grid-template-columns: auto;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.selectGcn > div > div > label, .selectBill > div > div > label, .selectThc > div > label{
    font-weight: var(--fontWeight);
    color: var(--customBlue);
}