.heading{
    background-color: var(--customLightBlue);
    color: var(--black);
    font-size: 0.9rem;
    text-align: center;
    font-weight: var(--fontWeight);
    height: 1.7rem;
    margin-bottom: 0.5rem;
}
.heading > div{
    display: inline-block;
    vertical-align: -webkit-baseline-middle;
}
#partyDetailsSection{
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10rem;
    padding: var(--paddingSide);
    margin-bottom: 0.5rem;
}
#btnContainer{
    text-align: center;
    margin-bottom: 0.5rem;
}
@media only screen and (max-width : 1230px) {
    #partyDetailsSection {
        column-gap: 3rem;
    }
}
@media only screen and (max-width : 850px) {
    #partyDetailsSection {
        column-gap: 1rem;
    }
}
@media only screen and (max-width : 650px) {
    #partyDetailsSection {
        grid-template-columns: auto;
        column-gap: 0;
        row-gap: 0.5rem;
    }
}