.bookingSection{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.billSection{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.clientSection{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.destinationSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.brokerSection{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.descriptionSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.packageSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.ownerSection{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.driverSection{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.thcSection{
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.truckSection{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.brokerReport{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.monthlyBooking{
    display: grid;
    grid-template-columns: auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: space-evenly;
}
.billSubmission{
    display: grid;
    grid-template-columns: auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: space-evenly;
}
.outstandingBillReport{
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.envelopSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.chequePrintInput{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.trackingInputSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.uploadSection{
    display: grid;
    grid-template-columns: auto auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}
.editDateSection{
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: center;
}
.bookingSection > div > div > label, .billSection > div > div > label, .clientSection > div > div > label, .destinationSection > div > div > label, .brokerSection > div > div > label, .descriptionSection > div > div > label, .packageSection > div > div > label, .ownerSection > div > div > label, .driverSection > div > div > label, .thcSection > div > div > label, .truckSection > div > div > label, .brokerReport > div > div > label, .monthlyBooking > div > div > label, .billSubmission > div > div > label, .outstandingBillReport > div > div > label, .envelopSection > div > div > label, .chequePrintInput > div > div > label, .trackingInputSection > div > div > label, .uploadSection > div > div > label, .editDateSection > div > div > label {
    color: var(--customBlue);
    font-weight: var(--fontWeight);
}
.bookingSection > div > div> input, .billSection > div > div > input, .billSection > div > div > textarea, .clientSection > div > div > input, .clientSection > div > div > textarea, .destinationSection > div > div > input, .destinationSection > div > div > textarea, .brokerSection > div > div > input, .brokerSection > div > div > textarea, .descriptionSection > div > div > input, .descriptionSection > div > div > textarea, .packageSection > div > div > input, .packageSection > div > div > textarea, .ownerSection > div > div > input, .ownerSection > div > div > textarea, .driverSection > div > div > input, .driverSection > div > div > textarea, .thcSection > div > div > input, .thcSection > div > div > textarea, .truckSection > div > div > input, .truckSection > div > div > textarea, .brokerReport > div > div > input, .brokerReport > div > div > textarea, .monthlyBooking > div > div > input, .monthlyBooking > div > div > textarea, .billSubmission > div > div > input, .billSubmission > div > div > textarea, .outstandingBillReport > div > div > input, .outstandingBillReport > div > div > textarea, .envelopSection > div > div > input, .chequePrintInput > div > div > input, .chequePrintInput > div > div > textarea, .trackingInputSection > div > div > input, .uploadSection > div > div > input, .uploadSection > div > div > textarea, .editDateSection > div > div > input {
    width: var(--inputBoxWidth);
    height: var(--inputBoxHeight);
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--inputBoxBackgroundColor);
    margin-top: 0.5rem;
    padding: 0.2rem;
    color: var(--fillColor);
}
.envelopSection > div > div > textarea {
    width: 90%;
    height: 5rem;
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--inputBoxBackgroundColor);
    margin-top: 0.5rem;
    padding: 0.2rem;
    color: var(--fillColor);
}
.bookingSection > div > div> input:focus, .billSection > div > div > input:focus, .billSection > div > div > textarea:focus, .clientSection > div > div > input:focus, .clientSection > div > div > textarea:focus, .destinationSection > div > div > input:focus, .destinationSection > div > div > textarea:focus, .brokerSection > div > div > input:focus, .brokerSection > div > div > textarea:focus, .descriptionSection > div > input:focus, .descriptionSection > div > textarea:focus, .packageSection > div > input:focus, .packageSection > div > textarea:focus, .ownerSection > div > div > input:focus, .ownerSection > div > div > textarea:focus, .driverSection > div > div > input:focus, .driverSection > div > div > textarea:focus, .thcSection > div > div > input:focus, .thcSection > div > div > textarea:focus, .truckSection > div > div > input:focus, .truckSection > div > div > textarea:focus, .brokerReport > div > div > input:focus, .brokerReport > div > div > textarea:focus, .monthlyBooking > div > div > input:focus, .monthlyBooking > div > div > textarea:focus, .billSubmission > div > div > input:focus, .billSubmission > div > div > textarea:focus, .outstandingBillReport > div > div > input:focus, .outstandingBillReport > div > div > textarea:focus, .envelopSection > div > div > input:focus, .envelopSection > div > div > textarea:focus, .chequePrintInput > div > div > input:focus, .chequePrintInput > div > div > textarea:focus, .trackingInputSection > div > div > input:focus, .uploadSection > div > div > input:focus, .uploadSection > div > div > textarea:focus, .editDateSection > div > div > input:focus {
    background-color: #007acc;
    color: white;
}
.cnercneeSection > div {
    display: grid;
    grid-template-columns: auto;
    padding: var(--paddingSide);
    gap: 0.5rem;
    justify-content: center;
}
.cnercneeSection > div > div > label{
    color: var(--customBlue);
    font-weight: var(--fontWeight);
}
.cnercneeSection > div  > div > input,textarea{
    width: 17.5rem;
    height: var(--inputBoxHeight);
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--offWhite);
    padding: 0.2rem;
    color: var(--fillColor);
}
.cnercneeSection > div > div > input:focus, .cnercneeSection > div > div > textarea:focus {
    background-color: #007acc;
    color: white;
}
#amountInWordsDiv{
    grid-column-start: 3;
    grid-column-end: 6;
}
#remarkDiv{
    grid-column-start: 1;
    grid-column-end: 6;
}
#amountInWords{
    width: 85.5%;
    font-weight: var(--fontWeight);
    font-size: 0.9rem;
    padding: 0.2rem;
}
#grandTotal{
    font-weight: var(--fontWeight);
    font-size: 0.9rem;
    padding: 0.2rem;
}
#remark{
    width: 91.32%;
}
#pod{
    width: auto;
    height: auto;
}
@media only screen and (max-width : 1230px) {
    .bookingSection, .billSection, .truckSection {
        grid-template-columns: auto auto auto auto;
    }
    #amountInWordsDiv{
        grid-column-start: 1;
        grid-column-end: 3;
    }
    #remarkDiv{
        grid-column-start: 3;
        grid-column-end: 5;
    }
    .cnercneeSection > div {
        padding: 0 1rem;
    }
}
@media only screen and (max-width : 1000px) {
    .bookingSection, .thcSection, .billSection, .truckSection, .outstandingBillReport {
        grid-template-columns: auto auto auto;
    }
    #amountInWordsDiv{
        grid-column-start: 1;
        grid-column-end: 4;
    }
    #remarkDiv{
        grid-column-start: 1;
        grid-column-end: 4;
    }
    .cnercneeSection > div {
        padding: 0 0;
    }
}
@media only screen and (max-width : 850px) {
    .bookingSection, .thcSection, .billSection, .chequePrintInput, .truckSection, .brokerReport, .outstandingBillReport, .clientSection, .brokerSection, .ownerSection, .driverSection {
        grid-template-columns: auto auto;
    }
    #amountInWordsDiv{
        grid-column-start: 1;
        grid-column-end: 3;
    }
    #remarkDiv{
        grid-column-start: 1;
        grid-column-end: 3;
    }
}
@media only screen and (max-width : 650px) {
    .bookingSection, .thcSection, .billSection, .chequePrintInput, .truckSection, .brokerReport, .monthlyBooking, .billSubmission, .outstandingBillReport, .uploadSection, .clientSection, .brokerSection, .ownerSection, .driverSection {
        grid-template-columns: auto;
        justify-content: center;
    }
    #amountInWordsDiv{
        grid-column-start: 1;
        grid-column-end: 2;
    }
    #remarkDiv{
        grid-column-start: 1;
        grid-column-end: 2;
    }
    #amountInWords{
        width: 95%;
    }
    #remark{
        width: 95%;
    }
}