.truckSection > div > div > div > div{
    display: inline-block;
}
.addButton, .removeButton{
    color: var(--offwhite);
    background-color: var(--customBlue);
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 0.2rem;
}
.truckSection > div > div > div > input[class=widthChange]{
    width: 5rem;
}
.truckSection > div > div > div > input{
    width: var(--inputBoxWidth);
    height: var(--inputBoxHeight);
    outline: none;
    border: var(--inputBoxBorder);
    background-color: var(--inputBoxBackgroundColor);
    margin-top: 0.5rem;
    color: var(--fillColor);
}
.truckSection > div > div > div > input:focus{
    background-color: #007acc;
    color: white;
}
.paddingTopPointTwo {
    padding-top: 0.2rem;
}
.paddingTopPointTwo > label {
    color: var(--customBlue);
    font-weight: var(--fontWeight);
    margin-right: 0.2rem;
}