.darkSwitchSection {
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
}

.darkSwitchSection>svg>path {
    stroke: var(--strokeColor);
    stroke-width: 5;
    transition: stroke 0.8s;
}

.darkSwitchSection>svg>path:first-child {
    fill: var(--offwhite);
    transition: fill 0.8s;
}

.darkSwitchSection>svg>path:last-child {
    fill: var(--strokeColor);
    transition: fill 0.8s;
}