.backgroundWhite::before {
    background-image: none;
}

#HomeSection {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    height: 85vh;
}

#HomeSection>div:first-child {
    text-align: right;
}

#LoginSection {
    display: grid;
    grid-template-columns: auto;
    align-content: space-evenly;
    width: 20rem;
    height: 30vh;
    justify-items: center;
}

#LoginSection>div:first-child {
    height: 1rem;
    color: red;
    font-weight: var(--fontWeight);
    padding: 0.2rem;
}

.textBox {
    outline: none;
    color: var(--customBlue);
    padding: 0.2rem;
    background-color: var(--offwhite);
    border: var(--inputBoxBorder);
    font-weight: bold;
}

.textBox:focus {
    background-color: var(--customBlue);
    color: var(--offwhite);
}

.navbarButton {
    color: var(--offwhite);
    background-color: var(--customBlue);
    border: none;
    height: var(--btnheight);
    width: var(--btnwidth);
    cursor: pointer;
    outline: none;
}

.navbarButton:hover {
    color: var(--customBlue);
    background-color: var(--offwhite);
    border: 0.1rem solid var(--customBlue);
}

#sstSymbolHome {
    height: 10rem;
    width: auto;
}
@media only screen and (max-width : 650px) {
    #LoginSection {
        width: 10rem;
        padding: 0 1rem;
    }
    #sstSymbolHome {
        height: 8rem;
    }
}
@media only screen and (max-width : 500px) {
    #HomeSection {
        grid-template-columns: auto;
        justify-items: center;
        align-items: baseline;
        grid-template-rows: 20% auto;
    }
    #LoginSection {
        width: 80%;
    }
    #sstSymbolHomeDiv {
        margin-top: 5%;
    }
    #sstSymbolHome {
        height: 5rem;
    }
}