#ContactInfo {
    grid-column-start: 1;
    grid-column-end: 4;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    column-gap: 2rem;
}
.contactText{
    display: inline-block;
    vertical-align: super;
    color: var(--customBlue);
}
@media only screen and (max-width : 600px) {
    #ContactInfo {
        column-gap: 0.5rem;
        font-size: 0.6rem;
    }
}
@media only screen and (max-width : 400px) {
    #ContactInfo {
        grid-template-columns: auto auto;
    }
    #ContactInfo > div:last-child {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;
    }
}