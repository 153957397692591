:root{
  --offwhite: #efefef;
  --font: Verdana, Geneva, Tahoma, sans-serif;
  --fontSize: 0.8rem;
  --btnheight: 2rem;
  --btnwidth: 5.5rem;
  --paddingSide: 0 5rem;
  --fontWeight: bold;
  --inputBoxWidth: 8rem;
  --inputBoxHeight: 1rem;
  --selectBoxWidth: 8.5rem;
  --selectBoxHeight: 1.5rem;
  --inputBoxBorder: 0.15rem solid rgb(195 213 236);
  --inputBoxShadow: 0px 0px 10px rgb(41 81 132);
  --customBlue: rgb(41, 81, 132);
  --customLightBlue : rgb(163 187 218);
  --black : black;
  --strokeColor: black;
  --fillColor: black;
  --inputBoxBackgroundColor: white;
  --colorScheme: light;
}
@media only screen and (max-width : 750px) {
  :root {
    --paddingSide : 0 2rem;
  }
}
@media only screen and (max-width : 650px) {
  :root {
    --paddingSide : 0 1rem;
    --inputBoxWidth: 27rem;
    --selectBoxWidth: 27rem;
  }
}
@media only screen and (max-width : 500px) {
  :root {
    --inputBoxWidth: 17rem;
    --selectBoxWidth: 17rem;
  }
}