#messageBox{
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13rem;
    height: 6rem;
    margin: -3rem 0 0 -6.5rem;
    background-color: var(--offwhite);
    z-index: 10;
    text-align: -webkit-center;
    font-family: var(--font);
    font-size: var(--fontSize);
}
#messageBoxChanged {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13rem;
    height: 11rem;
    margin: -5.5rem 0 0 -6.5rem;
    background-color: var(--offwhite);
    z-index: 10;
    text-align: -webkit-center;
    font-family: var(--font);
    font-size: var(--fontSize);
}

#copy,
#freightType,
#gcnNo {
    font-size: 0.8rem;
}

#overley-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 5;
    background: var(--strokeColor);
    filter: alpha(opacity=40);
}

.messageTopDiv {
    text-align: left;
    font-weight: bold;
    padding: 7%;
    color: var(--strokeColor);
}