#locationSection{
    height: 60vh;
    text-align: center;
    margin-top: 0.5rem;
    font-family: var(--font);
    font-size: var(--fontSize);
    font-weight: var(--fontWeight);
    padding: var(--paddingSide);
}
iframe{
    margin-top: 0.5rem;
}