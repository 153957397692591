.logoutSection {
    height: 1.5rem;
    width: 1.5rem;
}

.logoutSection>svg>path {
    fill: none;
    stroke: red;
    stroke-width: 0.5rem;
    stroke-linecap: round;
}
