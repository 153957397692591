.deleteSection {
    height: 1.4rem;
    width: 1.4rem;
    cursor: pointer;
    display: inline-block;
}

.deleteSection>svg>path,
.deleteSection>svg>line {
    stroke: var(--strokeColor);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 2;
    fill: none;
}